import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Transaction } from '@shared/interfaces/transaction';
import {
  CardToken,
  PaymentMethodConfig,
  PaymentMethodType,
  InstallmentPeriod
} from '@shared/interfaces/payment-method';
import { BillingInfo } from '@shared/interfaces/billingInfo';
import { CartInfo } from '@shared/interfaces/cartInfo';
import {
  ShippingInfo,
  ShippingKeys,
  ShippingKeysNames
} from '@shared/interfaces/shippingInfo';
import { PayType, PayUrlMap, PayPayload } from '@shared/interfaces/payType';
import {
  CustomField,
  CustomFieldPayload
} from '@shared/interfaces/custom-field';
import { ApiService } from '@core/api/api.service';
import { XpayService } from '@community/shared/xpay.service';
import { LocalStorageService } from '@core/web-storage/local-storage.service';
import { PromocodeResult } from '@shared/interfaces/promocode';
import { TotalBreakdownService } from '@community/shared/total-breakdown.service';
import { FeesAddedToAmount } from '@shared/interfaces/amount-breakdown';
import { Utils } from './utils';

import { TranslocoService } from '@ngneat/transloco';

declare var gtag;
declare var fbq;

@Injectable({
  providedIn: 'root'
})
export class PayService {
  private payUrlMap: PayUrlMap = {
    bill: 'payments/pay/bills',
    event: 'payments/pay/event',
    academy: 'payments/pay/academy',
    team: 'payments/pay/player-bill',
    masyaf: 'payments/pay/masyaf',
    directOrder: 'payments/pay/direct-order',
    variableAmount: 'v1/payments/pay/variable-amount',
    training: 'payments/pay/training',
    service: 'payments/pay/service',
    product: 'payments/pay/product'
  };

  private prepareAmountURL = 'v1/payments/prepare-amount/';
  private getInstallmentPeriodsURL = 'payments/pay/installment-periods/';

  private cart = new BehaviorSubject(null);
  cart$ = this.cart.asObservable();

  private payType = new BehaviorSubject<PayType>(null);
  payType$ = this.payType.asObservable();

  private billingInfo = new BehaviorSubject<BillingInfo>(null);
  billingInfo$ = this.billingInfo.asObservable();

  private shippingInfo = new BehaviorSubject<ShippingInfo>(null);
  shippingInfo$ = this.shippingInfo.asObservable();

  private transactionInfo = new BehaviorSubject<Transaction>(null);
  transactionInfo$ = this.transactionInfo.asObservable();

  private hasShipping = new BehaviorSubject<boolean>(false);
  hasShipping$ = this.hasShipping.asObservable();

  private hasMobileVerification = new BehaviorSubject<boolean>(false);
  hasMobileVerification$ = this.hasMobileVerification.asObservable();

  private customFields = new BehaviorSubject<CustomField[]>(null);
  customFields$ = this.customFields.asObservable();

  private customFieldsValues = new BehaviorSubject<CustomFieldPayload[]>(null);
  customFieldsValues$ = this.customFieldsValues.asObservable();

  private paymentMethodConfig = new BehaviorSubject<PaymentMethodConfig>(null);
  paymentMethodConfig$ = this.paymentMethodConfig.asObservable();

  // promocode here represents a state model of promocode result but named promocode because it's shorter
  private promocodeResult = new BehaviorSubject<PromocodeResult>(null);
  promocodeResult$ = this.paymentMethodConfig.asObservable();

  private currentPayStep = new BehaviorSubject<number>(null);
  currentPayStep$ = this.currentPayStep.asObservable();

  private paymentMethods = new BehaviorSubject<PaymentMethodType[]>(null);
  paymentMethods$ = this.paymentMethods.asObservable();

  private shippinginCustomfields = new BehaviorSubject<boolean>(false);
  shippinginCustomfields$ = this.shippinginCustomfields.asObservable();

  private hasRecurringPayment = new BehaviorSubject<boolean>(false);
  hasRecurringPayment$ = this.hasRecurringPayment.asObservable();

  private installmentPeriod = new BehaviorSubject<number>(0);
  installmentPeriod$ = this.installmentPeriod.asObservable();

  private _source = new BehaviorSubject<string | null>(null);
  source$ = this._source.asObservable();

  constructor(
    private api: ApiService,
    private localStorageService: LocalStorageService,
    private communityService: XpayService,
    private breakdownService: TotalBreakdownService,
    private translateService: TranslocoService
  ) {}

  // methods for shippinginCustomfields
  changeShippinginCustomfields(shippinginCustomfields: boolean) {
    this.localStorageService.setItem('sic', shippinginCustomfields);

    this.shippinginCustomfields.next(shippinginCustomfields);
  }

  public get currentShippinginCustomfields() {
    // In Memory transaction value
    const imShippinginCustomfields = this.shippinginCustomfields.getValue();

    // Local Storage transaction value
    const lsShippinginCustomfields = this.localStorageService.getItem('sic');

    // check if in memory transaction value is there and if not
    if (imShippinginCustomfields) {
      return imShippinginCustomfields;

      // check if local storage transaction value is there and if not
    } else if (lsShippinginCustomfields) {
      return lsShippinginCustomfields;

      // return null
    } else {
      return null;
    }
  }

  // methods for promocode

  changePromocodeResult(promocodeResult: PromocodeResult) {
    this.promocodeResult.next(promocodeResult);
  }

  getPromocodeResult() {
    return this.promocodeResult.value;
  }

  getCardList(userId: number): Observable<CardToken[]> {
    return this.api.get<CardToken[]>(`users/${userId}/cards`);
  }

  changeTransactionInfo(transactionInfo: Transaction) {
    // set transaction info in localstorage
    if ('iframe_url' in transactionInfo && transactionInfo.iframe_url) {
      this.localStorageService.setItem('iu', transactionInfo.iframe_url);
      this.localStorageService.setItem('ts', {
        // transactionStatus: transactionInfo.is_successful,
        transactionStatus: transactionInfo.transaction_status,
        transactionId: transactionInfo.transaction_id,
        transactionUUID: transactionInfo.transaction_uuid,
        bill_reference: transactionInfo.bill_reference
      });
    } else {
      this.localStorageService.setItem('ts', {
        // transactionStatus: transactionInfo.is_successful,
        transactionStatus: transactionInfo.transaction_status,
        transactionId: transactionInfo.transaction_id,
        transactionUUID: transactionInfo.transaction_uuid,
        bill_reference: transactionInfo.bill_reference
      });
    }

    this.transactionInfo.next(transactionInfo);
  }

  getInstallmentPeriodList(
    communityId: string
  ): Observable<InstallmentPeriod[]> {
    return this.api.get<InstallmentPeriod[]>(
      `${this.getInstallmentPeriodsURL}${communityId}`
    );
  }

  changeInstallmentPeriod(duration: number) {
    // set billing info in localstorage
    this.localStorageService.setItem('ip', duration);

    this.installmentPeriod.next(duration);
  }

  public get currentInstallmentPeriod(): number {
    // In Memory Installment Period value
    const imInstallmentPeriodValue = this.installmentPeriod.getValue();

    // Local Storage Installment Period value
    const lsInstallmentPeriodValue = this.localStorageService.getItem('ip');

    // check if in memory Installment Period value is there and if not
    if (imInstallmentPeriodValue) {
      return imInstallmentPeriodValue;

      // check if local storage Installment Period value is there and if not
    } else if (lsInstallmentPeriodValue) {
      return lsInstallmentPeriodValue;

      // return 0
    } else {
      return 0;
    }
  }

  public get transaction(): Transaction | null {
    // In Memory transaction value
    const imTransactionValue = this.transactionInfo.getValue();

    // Local Storage transaction value
    const lsTransactionValue = this.localStorageService.getItem('ts');

    // check if in memory transaction value is there and if not
    if (imTransactionValue) {
      return imTransactionValue;

      // check if local storage transaction value is there and if not
    } else if (lsTransactionValue) {
      return lsTransactionValue;

      // return null
    } else {
      return null;
    }
  }

  changePayType(payType: PayType) {
    // set pay type in localstorage
    this.localStorageService.setItem('pt', payType);

    this.payType.next(payType);
  }

  public get currentPayType(): PayType | null {
    // In Memory pay type value
    const imPayTypeValue = this.payType.getValue();

    // Local Storage pay type value
    const lsPayTypeValue = this.localStorageService.getItem('pt');

    // check if in memory pay type value is there and if not
    if (imPayTypeValue) {
      return imPayTypeValue;

      // check if local storage pay type value is there and if not
    } else if (lsPayTypeValue) {
      return lsPayTypeValue;

      // return null
    } else {
      return null;
    }
  }

  changeBillingInfo(billingInfo: BillingInfo) {
    // set billing info in localstorage
    this.localStorageService.setItem('bi', billingInfo);

    this.billingInfo.next(billingInfo);
  }

  public get currentBillingInfo(): BillingInfo | null {
    // In Memory billing info value
    const imBillingInfoValue = this.billingInfo.getValue();

    // Local Storage billing info value
    const lsBillingInfoValue = this.localStorageService.getItem('bi');

    // check if in memory billing info value is there and if not
    if (imBillingInfoValue) {
      return imBillingInfoValue;

      // check if local storage billing info value is there and if not
    } else if (lsBillingInfoValue) {
      return lsBillingInfoValue;

      // return null
    } else {
      return null;
    }

    // return this.billingInfo.value;
  }

  changeShippingInfo(shippingInfo: ShippingInfo) {
    // set shipping info in localstorage
    this.localStorageService.setItem('si', shippingInfo);

    this.shippingInfo.next(shippingInfo);
  }

  public get currentShippingInfo(): ShippingInfo | null {
    // In Memory Shipping info value
    const imShippingInfoValue = this.shippingInfo.getValue();

    // Local Storage Shipping info value
    const lsShippingInfoValue = this.localStorageService.getItem('si');

    // check if in memory Shipping info value is there and if not
    if (imShippingInfoValue) {
      return imShippingInfoValue;

      // check if local storage Shipping info value is there and if not
    } else if (lsShippingInfoValue) {
      return lsShippingInfoValue;

      // return null
    } else {
      return null;
    }
  }

  changeCartInfo<T>(cartInfo: T) {
    // set cart info in localstorage
    this.localStorageService.setItem('ci', cartInfo);

    this.cart.next(cartInfo);
  }

  public get currentCartInfo(): CartInfo | null {
    // In Memory cart info value
    const imCartInfoValue = this.cart.getValue();

    // Local Storage cart info value
    const lsCartInfoValue = this.localStorageService.getItem('ci');

    // check if in memory cart info value is there and if not
    if (imCartInfoValue) {
      return imCartInfoValue;

      // check if local storage cart info value is there and if not
    } else if (lsCartInfoValue) {
      return lsCartInfoValue;

      // return null
    } else {
      return null;
    }
  }

  changeHasShippingStatus(flag: boolean) {
    // set has shipping in localstorage
    this.localStorageService.setItem('hs', flag);

    this.hasShipping.next(flag);
  }
  changeHasRecurringPaymentStatus(flag: boolean) {
    this.localStorageService.setItem('rp', flag);
    this.hasRecurringPayment.next(flag);
  }
  changeHasMobileVerification(flag: boolean) {
    this.hasMobileVerification.next(flag);
  }

  public get currentHasShippingStatus(): boolean | null {
    // In Memory has shipping value
    const imHasShippingValue = this.hasShipping.getValue();

    // Local Storage cart info value
    const lsHasShippingValue = this.localStorageService.getItem('hs');

    // check if in memory cart info value is there and if not
    if (imHasShippingValue) {
      return imHasShippingValue;

      // check if local storage cart info value is there and if not
    } else if (lsHasShippingValue) {
      return lsHasShippingValue;

      // return null
    } else {
      return null;
    }
  }
  public get currentHasRecurringPaymentStatus(): boolean | null {
    const imHasRecurringPaymentValue = this.hasRecurringPayment.getValue();

    const lsRecurringPaymentValue = this.localStorageService.getItem('rp');

    if (imHasRecurringPaymentValue) {
      return imHasRecurringPaymentValue;
    } else if (lsRecurringPaymentValue) {
      return lsRecurringPaymentValue;
    } else {
      return null;
    }
  }

  public get currentHasMobileVerification(): boolean | null {
    // In Memory has mobile verification value
    const imHasMobileVerificationValue = this.hasMobileVerification.getValue();

    // Local Storage cart info value
    const lsHasMobileVerificationValue = this.localStorageService.getItem('mv');

    // check if in memory cart info value is there and if not
    if (imHasMobileVerificationValue) {
      return imHasMobileVerificationValue;

      // check if local storage cart info value is there and if not
    } else if (lsHasMobileVerificationValue) {
      return lsHasMobileVerificationValue;

      // return null
    } else {
      return null;
    }
  }

  changeCustomFields(customFields: CustomField[]) {
    // set has shipping in localstorage
    this.localStorageService.setItem('cf', customFields);

    this.customFields.next(customFields);
  }

  public get currentCustomFields(): CustomField[] {
    // In Memory custom fields value
    const imCustomFieldsValue = this.customFields.getValue();

    // Local Storage custom fields value
    const lsCustomFieldsValue = this.localStorageService.getItem('cf');

    // check if in memory custom fields value is there and if not
    if (imCustomFieldsValue) {
      return imCustomFieldsValue;

      // check if local storage custom fields value is there and if not
    } else if (lsCustomFieldsValue) {
      return lsCustomFieldsValue;

      // return null
    } else {
      return null;
    }
  }

  changeCustomFieldsValues(customFieldsValues: CustomFieldPayload[]) {
    this.customFieldsValues.next(customFieldsValues);
  }

  public get currentHasCustomStatus(): boolean {
    if (this.currentCustomFields && this.currentCustomFields.length > 0) {
      return true;
    }
  }

  changePaymentMethodConfig(paymentMethodConfig: PaymentMethodConfig) {
    // set payment method config in localstorage
    this.localStorageService.setItem('pm', paymentMethodConfig);

    this.paymentMethodConfig.next(paymentMethodConfig);
  }

  public get currentPaymentMethodConfig(): PaymentMethodConfig | null {
    // In Memory payment method config value
    const imPaymentMethodConfigValue = this.paymentMethodConfig.getValue();

    // Local Storage cart info value
    const lsPaymentMethodConfigValue = this.localStorageService.getItem('pm');

    // check if in memory cart info value is there and if not
    if (imPaymentMethodConfigValue) {
      return imPaymentMethodConfigValue;

      // check if local storage cart info value is there and if not
    } else if (lsPaymentMethodConfigValue) {
      return lsPaymentMethodConfigValue;

      // return null
    } else {
      return null;
    }
  }

  changeCurrentPayStep(payStepIndex: number) {
    // set payment step name
    this.localStorageService.setItem('ps', payStepIndex);

    this.currentPayStep.next(payStepIndex);
  }

  public get payStep(): number {
    // In Memory pay step value
    const imPayStepValue = this.currentPayStep.getValue();

    // Local Storage pay step value
    const lsPayStepValue = this.localStorageService.getItem('ps');

    // check if in memory pay step value is there and if not
    if (imPayStepValue) {
      return imPayStepValue;

      // check if local storage pay step value is there and if not
    } else if (lsPayStepValue) {
      return lsPayStepValue;

      // return null
    } else {
      return null;
    }
  }

  changePaymentMethods(paymentMethods: PaymentMethodType[]) {
    // set payment methods
    this.localStorageService.setItem('pmth', paymentMethods);

    this.paymentMethods.next(paymentMethods);
  }

  public get currentPaymentMethods(): PaymentMethodType[] {
    // In Memory payment methods value
    const imValue = this.paymentMethods.getValue();

    // Local Storage payment methods value
    const lsValue = this.localStorageService.getItem('pmth');

    // check if in memory payment methods value is there and if not
    if (imValue) {
      return imValue;

      // check if local storage payment methods value is there and if not
    } else if (lsValue) {
      return lsValue;

      // return null
    } else {
      return null;
    }
  }

  changeSource(source: string) {
    if (source) {
      this.localStorageService.setItem('source', source);
    } else {
      source = null;
    }

    this._source.next(source);
  }

  public get source(): string | null {
    return this._source.getValue();
  }

  clearLocalStoragePayData() {
    const localstorageItems = [
      'ci',
      'bi',
      'iu',
      'mv',
      'rp',
      'ts',
      'pt',
      'si',
      'hs',
      'pm',
      'cf',
      'ps',
      'pmth',
      'tb',
      'sic',
      'source'
    ];

    // clear cart info / ci
    // clear billing info / bi
    // clear mobile verifiction / mv
    // clear iframe url / iu
    // clear transaction State / ts
    // clear pay type / pt
    // clear shipping info / si
    // clear has shipping flag / hs
    // clear payment method config / pm
    // clear custom fields / cf
    // clear current pay step /ps
    // clear payment methods / pmth
    // clear amount breakdown / tb
    // clear shipping in custom field flag
    // clear source
    localstorageItems.forEach(el => this.localStorageService.removeItem(el));
  }

  makePayment(): Observable<Transaction> {
    const paymentMethodConfig = this.currentPaymentMethodConfig;
    const currentPromocodeResult = this.promocodeResult.getValue(); // Get the current value of the BehaviorSubject
    // Initialize promocodeValue based on paymentMethodConfig.type
    let promocodeValue: PromocodeResult;
    switch (paymentMethodConfig.type) {
      case PaymentMethodType.FAWRY:
        promocodeValue = currentPromocodeResult
          ? { ...currentPromocodeResult, value: currentPromocodeResult.fawry }
          : { value: 0, promocode_id: 0 };
        break;
      case PaymentMethodType.CASHONDELIVERY:
        promocodeValue = currentPromocodeResult
          ? { ...currentPromocodeResult, value: currentPromocodeResult.cash }
          : { value: 0, promocode_id: 0 };
        break;
      case PaymentMethodType.KIOSK:
        promocodeValue = currentPromocodeResult
          ? { ...currentPromocodeResult, value: currentPromocodeResult.kiosk }
          : { value: 0, promocode_id: 0 };
        break;
      case PaymentMethodType.VALU:
        promocodeValue = currentPromocodeResult
          ? { ...currentPromocodeResult, value: currentPromocodeResult.valu }
          : { value: 0, promocode_id: 0 };
        break;
      case PaymentMethodType.UPG:
        promocodeValue = currentPromocodeResult
          ? { ...currentPromocodeResult, value: currentPromocodeResult.upg }
          : { value: 0, promocode_id: 0 };
        break;
      case PaymentMethodType.CARDTOKEN:
      case PaymentMethodType.NEWCARD:
      default:
        promocodeValue = currentPromocodeResult
          ? currentPromocodeResult
          : { value: 0, promocode_id: 0 };
        break;
    }
    let payload: PayPayload = this.initializePayload();

    payload = this.applyPromocodeIfValid(payload, promocodeValue);
    payload = this.configurePaymentMethod(
      payload,
      paymentMethodConfig,
      promocodeValue
    );
    payload = this.addCustomFields(payload);
    payload = this.handleShippingInfo(payload, paymentMethodConfig);

    if (this.hasRecurringPayment.value) {
      payload.is_recurring = true;
    }

    payload.amount = Utils.roundNumber(payload.amount, 4);

    const payUrl = this.payUrlMap[this.currentPayType];

    return this.api.post<Transaction>(payUrl, payload).pipe(
      map(transactionInfo => {
        this.changeTransactionInfo(transactionInfo);
        return transactionInfo;
      }),
      catchError(error => {
        // Handle error appropriately
        console.error('Payment error:', error);
        return throwError(error);
      })
    );
  }

  private initializePayload(): PayPayload {
    return {
      billing_data: { ...this.currentBillingInfo },
      ...this.currentCartInfo,
      community_id: this.communityService.communityId,
      pay_using: 'card',
      installment_period: 0,
      language: this.translateService.getActiveLang(),
      source: this.source // set source value
    };
  }

  private applyPromocodeIfValid(
    payload: PayPayload,
    promocodeValue: PromocodeResult
  ): PayPayload {
    if (promocodeValue && promocodeValue.value) {
      payload.promocode_id = promocodeValue.promocode_id;
      payload.amount = promocodeValue.value;
    }
    return payload;
  }

  private configurePaymentMethod(
    payload: PayPayload,
    paymentMethodConfig: PaymentMethodConfig,
    promocodeValue: PromocodeResult
  ): PayPayload {
    switch (paymentMethodConfig.type) {
      case PaymentMethodType.CASHONDELIVERY:
        payload.pay_using = 'cash';
        payload.amount =
          promocodeValue && promocodeValue.cash
            ? promocodeValue.cash +
              this.breakdownService.currentTotalBreakdown.cash
                .cash_collection_fees_amount
            : this.breakdownService.currentTotalBreakdown.cash
                .grand_total_amount +
              this.breakdownService.currentTotalBreakdown.cash
                .cash_collection_fees_amount;
        break;
      case PaymentMethodType.NEWCARD:
        payload.pay_using = 'card';
        break;
      case PaymentMethodType.VALU:
        payload.pay_using = 'valu';
        payload.amount =
          promocodeValue && promocodeValue.value
            ? promocodeValue.valu
            : this.breakdownService.currentTotalBreakdown.valu
                .grand_total_amount;
        break;
      case PaymentMethodType.INSTALLMENT:
        payload.pay_using = 'card';
        payload.installment_period = this.currentInstallmentPeriod;
        payload.amount =
          this.breakdownService.currentTotalBreakdown.sub_total_amount +
          this.breakdownService.currentTotalBreakdown.current_installment_fees +
          this.breakdownService.currentTotalBreakdown.vat_amount;
        break;
      case PaymentMethodType.FAWRY:
        payload.pay_using = 'fawry';
        payload.amount =
          promocodeValue && promocodeValue.value
            ? promocodeValue.value
            : this.breakdownService.currentTotalBreakdown.fawry
                .grand_total_amount;
        payload.language = this.translateService.getActiveLang();
        break;
      case PaymentMethodType.KIOSK:
        payload.pay_using = 'kiosk';
        payload.amount =
          promocodeValue && promocodeValue.value
            ? promocodeValue.value
            : this.breakdownService.currentTotalBreakdown.kiosk
                .grand_total_amount;
        break;
      case PaymentMethodType.UPG:
        payload.pay_using = 'meeza/digital';
        payload.amount =
          promocodeValue && promocodeValue.value
            ? promocodeValue.value
            : this.breakdownService.currentTotalBreakdown['meeza/digital']
                .grand_total_amount;
        break;
    }
    return payload;
  }

  private addCustomFields(payload: PayPayload): PayPayload {
    if (this.customFieldsValues.getValue()) {
      payload.custom_fields = this.customFieldsValues.getValue();
    }
    return payload;
  }

  private handleShippingInfo(
    payload: PayPayload,
    paymentMethodConfig: PaymentMethodConfig
  ): PayPayload {
    if (
      this.currentHasShippingStatus ||
      paymentMethodConfig.type === PaymentMethodType.CASHONDELIVERY
    ) {
      payload.billing_data = {
        ...payload.billing_data,
        ...this.currentShippingInfo
      };
      if (this.currentShippinginCustomfields) {
        payload.custom_fields = this.mergeCustomFieldsWithShippingInfo(
          payload.custom_fields
        );
      }
    }
    return payload;
  }

  private mergeCustomFieldsWithShippingInfo(customFields: any[]): any[] {
    const shippinginformation = this.currentShippingInfo;
    ShippingKeys.forEach(key => {
      if (key in shippinginformation) {
        customFields.push({
          field_label: ShippingKeysNames[key],
          field_value: shippinginformation[key]
        });
      }
    });
    return customFields;
  }

  // prepare amount endpoint (V1) takes community_id, amount and currency
  // it returns the total amount with fees added to it and may return cash and kiosk values if exists
  PrepareAmount(
    communityId: string,
    totalAmount: number,
    currency?: string
  ): Observable<FeesAddedToAmount> {
    return this.api.post<FeesAddedToAmount>(this.prepareAmountURL, {
      community_id: communityId,
      amount: totalAmount,
      currency: currency || 'EGP' // default to 'EGP' if not provided
    });
  }
  trackConversion() {
    gtag('event', 'click', {
      event_category: 'button',
      event_label: 'conversion'
    });
    fbq('track', 'Conversion');
  }
}
