import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { XpayService } from '@community/shared/xpay.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PayService } from '@pay/pay/pay.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TotalBreakdownService } from '@community/shared/total-breakdown.service';
import { PaymentModuleService } from '@community/shared/payment-module.service';
import { PayType } from '@shared/interfaces/payType';
import { Subject, Subscription } from 'rxjs';
import { VariableAmount } from '@shared/interfaces/variable-amount';
import {
  PrivateDonationCartInfo,
  PublicDonationCartInfo
} from '@shared/interfaces/cartInfo';
import { AmountBreakdown } from '@shared/interfaces/amount-breakdown';
import { ModuleScope } from '@shared/interfaces/module-scope';
import { MODULE_SCOPE } from '@shared/module-scope';
import { FeesAddedToAmount } from '@shared/interfaces/amount-breakdown';
@Component({
  selector: 'app-variable-amount-widget',
  templateUrl: './variable-amount-widget.component.html',
  styleUrls: ['./variable-amount-widget.component.scss']
})
export class VariableAmountWidgetComponent implements OnInit, OnDestroy {
  communityId: string = null;
  variableAmountTemplate: VariableAmount;
  minAmount = 0;
  variableAmountForm: FormGroup;
  membershipId: string;
  ngUnsubscribe = new Subject();
  alertMessage: string;
  timeout: any;
  submitted = false;
  amountBreakdown: AmountBreakdown;
  queryParams: Params;
  queryParamsSub: Subscription;

  constructor(
    private xpayService: XpayService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private payService: PayService,
    private fb: FormBuilder,
    private breakdownService: TotalBreakdownService,
    private paymentModuleService: PaymentModuleService,
    private route: ActivatedRoute,
    @Inject(MODULE_SCOPE) private scope: ModuleScope
  ) {}

  ngOnInit() {
    this.communityId = this.xpayService.communityId;

    this.variableAmountTemplate = this.activatedRoute.snapshot.data.va;

    if (this.variableAmountTemplate) {
      this.amountBreakdown = this.breakdownService.initTotalBreakdown(
        this.variableAmountTemplate,
        PayType.VARIABLEAMOUNT
      );

      this.initDonationForm(this.variableAmountTemplate.is_active);

      // set payment module data
      this.paymentModuleService.changePaymentModule({
        name: this.variableAmountTemplate.name
      });

      this.patchMembershipId();
    }

    this.queryParamsSub = this.route.queryParams.subscribe(
      params => (this.queryParams = params)
    );
  }

  get f() {
    return this.variableAmountForm;
  }

  get membershipControl() {
    return this.f.controls.membership_id;
  }

  get amountControl() {
    return this.f.controls.amount;
  }

  patchMembershipId() {
    this.xpayService.memberships$.subscribe(el => {
      if (el && el.length > 0) {
        this.membershipId = el[0].member_id;
        this.variableAmountForm.patchValue({
          membership_id: { membership_id: this.membershipId }
        });
      }
    });
  }

  initDonationForm(isActive: boolean) {
    this.variableAmountForm = this.fb.group({
      amount: ['', [Validators.required]],
      membership_id: []
    });

    if (!isActive) {
      this.membershipControl.disable();
      this.amountControl.disable();
    }
  }

  pay(variableAmountTemplate: VariableAmount) {
    this.submitted = true;
    if (this.variableAmountForm.valid) {
      // Clear stored pay data
      this.payService.clearLocalStoragePayData();

      const userInputAmount = +this.amountControl.value;

      // Call PrepareAmount to fetch the prepared breakdown
      this.payService
        .PrepareAmount(this.communityId, userInputAmount, this.variableAmountTemplate.currency)
        .subscribe(
          preparedAmount => {
            // Update the amount breakdown dynamically
            this.updateAmountBreakdown(preparedAmount, userInputAmount);

            // Prepare cart info based on member requirement
            const variableAmountCartInfo = this.prepareCartInfo(
              variableAmountTemplate,
              preparedAmount.total_amount,
              userInputAmount,
            );
            this.payService.changeCartInfo(variableAmountCartInfo);

            // Set payment type and source
            this.payService.changePayType(PayType.VARIABLEAMOUNT);
            this.payService.changeSource(this.queryParams.utm_source);

            // Navigate to the payment page
            const modeURLSegment = this.scope.mode ? `${this.scope.mode}/` : '';
            this.router.navigateByUrl(
              `${modeURLSegment}${this.scope.module}/${this.communityId}/pay`
            );
          },
          error => {
            console.error('Error preparing amount:', error);
            this.alertMessage =
              'Failed to prepare the amount. Please try again.';
          }
        );
    }
  }

  private updateAmountBreakdown(preparedAmount: FeesAddedToAmount, userInputAmount: number): void {
    // Update top-level fields
    this.amountBreakdown.grand_total_amount = preparedAmount.total_amount || 0;
    this.amountBreakdown.service_fees_amount =
      preparedAmount.xpay_fees_amount || 0;
    this.amountBreakdown.administrative_fees_amount =
      preparedAmount.community_fees_amount || 0;
    this.amountBreakdown.sub_total_amount = userInputAmount;
    this.amountBreakdown.installment_fees =
      preparedAmount['installment_fees'];
    // Update specific payment methods dynamically
    const paymentMethods = ['CASH', 'FAWRY', 'KIOSK', 'MEEZA/DIGITAL', 'VALU'];
    paymentMethods.forEach(method => {
      if (preparedAmount[method]) {
        this.amountBreakdown[method.toLowerCase()] = {
          grand_total_amount: preparedAmount[method].total_amount || 0,
          service_fees_amount: preparedAmount[method].xpay_fees_amount || 0,
          administrative_fees_amount:
            preparedAmount[method].community_fees_amount || 0
        };
      }
    });

    // Notify the breakdown service of the updated breakdown
    this.breakdownService.changeTotalBreakdownInfo(this.amountBreakdown);
  }

  private prepareCartInfo(
    variableAmountTemplate: VariableAmount,
    totalAmount: number,
    userInputAmount: number
  ): PrivateDonationCartInfo | PublicDonationCartInfo {
    if (variableAmountTemplate.require_member_id) {
      // Prepare cart info for private donation
      return {
        amount: totalAmount,
        original_amount: userInputAmount,
        currency: this.breakdownService.currentPaymentCurrency,
        variable_amount_id: this.variableAmountTemplate.id,
        membership_id: this.membershipControl.value.membership_id
      };
    } else {
      // Prepare cart info for public donation
      return {
        amount: totalAmount,
        original_amount: userInputAmount,
        currency: this.breakdownService.currentPaymentCurrency,
        variable_amount_id: this.variableAmountTemplate.id
      };
    }
  }

  onInputKeyPress(event: KeyboardEvent): void {
    const allowedChars = /[0-9]/;
    if (!allowedChars.test(event.key)) {
      event.preventDefault();
    }
  }

  trackConversion() {
    this.payService.trackConversion();
  }
  ngOnDestroy(): void {
    this.queryParamsSub.unsubscribe();
  }
}
