import { Injectable } from '@angular/core';
import {
  AmountBreakdown,
  Breakdown
} from 'src/app/shared/interfaces/amount-breakdown';
import { Currency } from '@shared/interfaces/currency';
import { Event } from 'src/app/shared/interfaces/event';

import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from '@core/web-storage/local-storage.service';
import { DirectOrder } from 'src/app/shared/interfaces/direct-order';
import { ApiService } from '@core/api/api.service';
import { Promocode, PromocodeResult } from '@shared/interfaces/promocode';
import { PayType } from '@shared/interfaces/payType';
import { PlayerBill, Academy } from '@shared/interfaces/sport';
import { Bill } from '@shared/interfaces/bills';
import { Utils } from '@pay/pay/utils';
import { VariableAmount } from '@shared/interfaces/variable-amount';
@Injectable({
  providedIn: 'root'
})
export class TotalBreakdownService {
  private breakdown: Breakdown = {
    sub_total_amount: 0,
    sub_total_amount_currency: null,
    vat_amount: 0,
    vat_amount_currency: null,
    service_fees_amount: 0,
    service_fees_amount_currency: null,
    administrative_fees_amount: 0,
    administrative_fees_currency: null,
    fine_amount: 0,
    fine_amount_currency: null,
    discount_amount: 0,
    discount_amount_currency: null,
    account_type: null,
    shipping_fees_amount: 0,
    shipping_fees_amount_currency: null,
    grand_total_amount: 0,
    grand_total_amount_currency: null,
    cash_collection_fees_amount: 0,
    cash_collection_fees_amount_currency: null,
    installment_fees: null,
    current_installment_fees: null
  };

  private totalBreakdown: AmountBreakdown = {
    ...this.breakdown,
    cash: {
      ...this.breakdown
    },
    kiosk: {
      ...this.breakdown
    },
    valu: {
      ...this.breakdown
    },
    'meeza/digital': {
      ...this.breakdown
    },
    fawry: {
      ...this.breakdown
    }
  };

  private totalBreakdownInfo = new BehaviorSubject<AmountBreakdown>(null);
  totalBreakdown$ = this.totalBreakdownInfo.asObservable();

  private paymentCurrency = new BehaviorSubject<Currency>(null);
  paymentCurrency$ = this.paymentCurrency.asObservable();

  apiUrl = 'promocodes/validate/';

  constructor(
    private localStorageService: LocalStorageService,
    private apiService: ApiService
  ) {}

  getTotalBreakdown(): Observable<AmountBreakdown> {
    return this.totalBreakdown$;
  }

  changeTotalBreakdownInfo(totalBreakdown: AmountBreakdown) {
    // set breakdown in localstorage
    this.localStorageService.setItem('tb', totalBreakdown);

    this.totalBreakdownInfo.next(totalBreakdown);
  }

  public get currentTotalBreakdown(): AmountBreakdown | null {
    // In Memory total breakdown value
    const imTotalBreakdownValue = this.totalBreakdownInfo.getValue();

    // Local Storage total breakdown value
    const lsTotalBreakdownValue = this.localStorageService.getItem('tb');

    // check if in memory total breakdown is there and if not
    if (imTotalBreakdownValue) {
      return imTotalBreakdownValue;

      // check if local storage total breakdown is there and if not
    } else if (lsTotalBreakdownValue) {
      return lsTotalBreakdownValue;
    } else {
      return null;
    }
  }

  changePaymentCurrency(currency: Currency) {
    // set currency in localstorage
    this.localStorageService.setItem('pc', currency);

    this.paymentCurrency.next(currency);
  }

  public get currentPaymentCurrency(): Currency | null {
    // In Memory payment currency value
    const imPaymentCurrencyValue = this.paymentCurrency.getValue();

    // Local Storage payment currency value
    const lsPaymentCurrencyValue = this.localStorageService.getItem('pc');

    // check if in memory payment currency is there and if not
    if (imPaymentCurrencyValue) {
      return imPaymentCurrencyValue;

      // check if local storage payment currency is there and if not
    } else if (lsPaymentCurrencyValue) {
      return lsPaymentCurrencyValue;
    } else {
      return null;
    }
  }

  clearLocalStorageBreakdownData() {
    const localstorageItems = ['tb', 'pc'];

    // clear total breakdown / tb
    // clear payment currency / pc
    localstorageItems.forEach(el => this.localStorageService.removeItem(el));
  }

  resetTotalBreakdown() {
    this.breakdown.sub_total_amount = 0;
    this.breakdown.sub_total_amount_currency = null;
    this.breakdown.vat_amount = 0;
    this.breakdown.vat_amount_currency = null;
    this.breakdown.service_fees_amount = 0;
    this.breakdown.service_fees_amount_currency = null;
    this.breakdown.fine_amount = 0;
    this.breakdown.fine_amount_currency = null;
    this.breakdown.discount_amount = 0;
    this.breakdown.discount_amount_currency = null;
    this.breakdown.shipping_fees_amount = 0;
    this.breakdown.shipping_fees_amount_currency = null;
    this.breakdown.grand_total_amount = 0;
    this.breakdown.grand_total_amount_currency = null;
    this.totalBreakdown = {
      ...this.breakdown,
      cash: {
        ...this.breakdown
      },
      kiosk: {
        ...this.breakdown
      },
      fawry: {
        ...this.breakdown
      },
      valu: {
        ...this.breakdown
      },
      'meeza/digital': {
        ...this.breakdown
      }
    };
  }

  initTotalBreakdown(
    payment: any,
    payType: PayType,
    amountBreakdown?: AmountBreakdown
  ) {
    this.resetTotalBreakdown();
    let currency: Currency;
    switch (payType) {
      case PayType.EVENT:
      case PayType.PRODUCT:
      case PayType.TRAINING:
      case PayType.SERVICE:
        currency = (payment as Event).tickets[0].total
          .grand_total_amount_currency;
        break;
      case PayType.DIRECTORDER:
        currency = (payment as DirectOrder).total.grand_total_amount_currency;
        this.totalBreakdown = (payment as DirectOrder).total;
        break;
      case PayType.BILL:
        currency = (payment as Bill).total.grand_total_amount_currency;
        break;
      case PayType.VARIABLEAMOUNT:
        currency = (payment as VariableAmount).currency as Currency;
        break;
      case PayType.TEAM:
        currency = (payment as PlayerBill).total.shipping_fees_amount_currency;
        this.totalBreakdown = (payment as PlayerBill).total;
        break;
      case PayType.ACADEMY:
        currency = (payment as Academy).total.grand_total_amount_currency;
        break;
      default:
        break;
    }

    this.changePaymentCurrency(currency);

    if (amountBreakdown) {
      this.totalBreakdown = amountBreakdown;
    }

    this.changeTotalBreakdownInfo(this.totalBreakdown);

    return { ...this.totalBreakdown };
  }

  getGrandTotalAmount() {
    return this.totalBreakdownInfo.getValue().grand_total_amount;
  }

  checkPromoCode(promoCode: Promocode): Observable<PromocodeResult> {
    promoCode.amount = Utils.roundNumber(promoCode.amount);

    return this.apiService.post<PromocodeResult>(this.apiUrl, promoCode);
  }
}
